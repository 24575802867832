import utilPrintTxt from './util/util-print-txt-prueba';
import utilPrintHtml from './util/util-print-html-prueba';

class printManager {
  format = '';
  utilPrint = null;
  text = '';
  printItems = [];

  constructor() {

  }

  printFromFormat(items, format = 'html') {
    this.printItems = items;
    this.format = format;
    if (this.format == 'html') {
      this.utilPrint = new utilPrintHtml();
    }
    if (this.format == 'text') {
      this.utilPrint = new utilPrintTxt();
    }
    this.text = this.utilPrint.render(this.printItems);
    this.utilPrint.print(this.text);
  }

  setLabelZPL(product, price, codebar, width) {
    str = '^XA';
    str = '^LL' + width;  //<- Label Length 
    str += '^FX ticket format.'; //comment
    str += '^CFA,10';
    str += '^FO5,5^FD' + product + '^FS';
    str += '^CFA,15';
    str += '^FO5,35^FD$' + price + '^FS';
    str += '^FX Third section with bar code.'; //comment
    str += '^BY1,2,20';
    str += '^FO5,65^AQN^FD' + codebar + '^FS';
    str += '^XZ';
    return str;
  }

  printLabelBarcode(label) {

    // https://gist.github.com/Lakerfield/1b77b03789525c9d0f13ddfeedee2efa
    //https://github.com/qzind/tray

    // ipcMain
    return new Promise((resolve, reject) => {
      try {
        // tslint:disable-next-line:no-string-literal
        const newWin = window.frames['printf'];
        newWin.document.open('text/plain')
        newWin.document.write(label);
        newWin.document.close();
        newWin.focus();
        newWin.print();
        newWin.close();
        resolve();
      } catch (error) {
        console.log('er', error);
        reject(error);
      }
    });
  }
}

export default new printManager();