import MixinModalDynamic from "@/components/mixins/MixinModalDynamic";
import printManager from "@/plugins/print-manager-prueba";

export default {
    mixins: [MixinModalDynamic],
    data() {
        return {
            documentId: null,
            dialogModifyCreditNote: false
        };
    },
    mounted() { },
    methods: {
        executeModifyCreditNote(item) {
            this.documentId = item.id;
            this.$nextTick(() => {
                this.$refs.modalModifyCreditNote.loadItem();
            });
            this.dialogModifyCreditNote = true;
        },
        closeModalModifyCreditNote() {
            this.dialogModifyCreditNote = false;
            this.$nextTick(() => {
                this.$refs.modalModifyCreditNote.clear();
            });
            this.search();
        },
        executeSendingDian(item) {
            this.generateElectronicInvoice('api/v1/generate-electronic-billing', item);
        },
        executeValidatedDian(item) {
            this.generateElectronicInvoice('api/v1/validated-electronic-billing', item);
        },
        generateElectronicInvoice(url, item) {
            item.object_type = 'acc_de_credit_note';
            this.$http
                .post(url,
                    { document: item, company: this.$store.getters.company.id },
                    true,
                    true
                )
                .then((response) => {
                    if (response.success) {
                        this.$nextTick(() => {
                            this.search();
                        });
                    }
                });
        },
        executeDownloadAttach(item) {
          return new Promise((resolve, reject) => {
              this.$http.get('api/v1/de-credit-note-download-attach/' + item.id, {}, true, false)
                  .then(data => {
                      let { item } = data;
                      if (data.success) {
                          window.open(this.$http.getBaseUrlPublicFiles() + 'tmp/' + data.item);
                      }
                      resolve({ item });
                  }).catch(err => {
                      console.log(err);
                  });
          });
      },
        executeSendEmail(item) {
          return new Promise((resolve, reject) => {
              this.$http.get('api/v1/de-credit-note-send-email/' + item.id, {}, true, true)
                  .then(data => {
                      let { item } = data;
                      if (data.success) {
                      }
                      resolve({ item });
                  }).catch(err => {
                      console.log(err);
                  });
          });
        },
        executePrintPdf(item) {
          return new Promise((resolve, reject) => {
              this.$http.get('api/v1/de-credit-note-pdf/' + item.id, null, true)
                  .then(data => {
                      let { item } = data;
                      if (data.success) {
                          window.open(this.$http.getBaseUrlPublicFiles() + 'tmp/' + data.item);
                      }
                      resolve({ item });
                  }).catch(err => {
                      console.log(err);
                      //this.loading = false;
                  });
          });
        },
        executePrintReceiptPos(item) {
            return new Promise((resolve, reject) => {
                this.$http.get('api/v1/de-credit-note-receipt-pos/' + item.id, null, true)
                    .then(data => {
                        let { item } = data.data;
                        if (data.success) {
                            printManager.printFromFormat(data.data, data.type);
                        }
                        resolve({ item });
                    }).catch(err => {
                        console.log(err);
                    });
            });
        },
        executeShowDian(item){
            return new Promise((resolve, reject) => {
                window.open(this.$http.getBaseUrlShowDocumentDian() + item.cude);
                resolve({ item });
            });
        }
    }
}



