class utilPrintTxt {
    
  constructor(){

  }

  render(data) {
    let html = '';
    html += this.renderContent(data);
    return html;
  }    

  renderContent(data) {
    let html = '';
    for (const value of data) { 
      if(value.type == 'line'){
        html += this.renderLine();
      }
      if(value.type == 'table'){
        html += this.renderTable(value);          
      }
      if(value.type == 'spacing'){
        html += this.renderSpacing();
      }
      if(value.type == 'text'){
        html += this.renderText(value);
      }
      if(value.type == 'total'){
        html += this.renderTotal(value);
      }
    }
    return html;
  }  

renderText(item) { 
  let html = ''; 
  html += `${item.value}`;
  html += '\n';
  return html;
}
renderLine() {
  return '____________________________________' + '\r\n' + '\r\n';
}

renderTotal(item) {
  let html = '';
  html += this.completeZero(item.label);
  html += item.value;
  html += '\n';
  return html;
}

renderTable(item) {
  let html = '';
  if (item.rows) {
    for (const i in item.rows) {
      if (item.rows.hasOwnProperty(i)) {
        for (const key in item.rows[i]) {
          if (item.rows[i].hasOwnProperty(key)) {
            html += `${item.rows[i][key]}` + '\n';
          }
        }
      }
    }
  }
  return html;
}

renderSpacing() {
  return '\n';
}

  print(html) {

  // ipcMain
    return new Promise((resolve, reject) => {
      try {
        // tslint:disable-next-line:no-string-literal
        const newWin = window.frames['printf'];
        newWin.document.write( '<pre>' + html + '</pre>' );
        newWin.print();
        newWin.document.close();
        resolve();
      } catch (error) {
          console.log('er', error);
          reject(error);
        }
    });
  }  

  strPad(str, padLength, padString, padType) {
    const len = padLength - str.length;
    if (len < 0) {
      return str;
    }
    const pad = new Array(len + 1).join(padString);
    if (padType === 'STR_PAD_LEFT') {
      return pad + str;
    }
    return str + pad;
  }

  completeZero(text, size = 20, type = null) {
    return this.strPad(text, size, ' ', type);
  }
}

export default  utilPrintTxt;